import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import "./App.css";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { Avatar, Box, Container, Link, Typography } from "@mui/material";
import { deepOrange, deepPurple } from "@mui/material/colors";
import { sizing } from "@mui/system";
import CssBaseline from "@mui/material/CssBaseline";

const theme = createTheme({
  components: {
    MuiStack: {
      defaultProps: {
        useFlexGap: true,
      },
    },
  },
});

function App() {
  return (
    <>
      <CssBaseline />
      <div className="App">
        <Container maxWidth="false">
          <ThemeProvider theme={theme}>
            <header className="App-header">
              <h1 class="prevent-select">Orhagen</h1>
            </header>
            <Box class="myimg" sx={{ p: "10vh" }}>
              <Link href="https://slektstre.orhagen.no">
                <img
                  src={process.env.PUBLIC_URL + "/familytree.png"}
                  className="familytree"
                  alt="familytree"
                />
              </Link>
              <Typography variant="h2" sx={{ p: 5 }}>
                Slektstre
              </Typography>
            </Box>
            <Stack
              direction="row"
              sx={{ justifyContent: "space-between", p: "5vh 30vw 5vh 30vw" }}
            >
              {[
                { name: "Ole P.", subdomain: "ole" },
                { name: "Erik", subdomain: "erik" },
                { name: "Tom", subdomain: "tom" },
              ].map((member, i) => {
                return (
                  <Box key={i}>
                    <Avatar
                      className="member-avatar"
                      component="a"
                      href={`https://${member.subdomain}.orhagen.no`}
                    >
                      {member.name}
                    </Avatar>
                  </Box>
                );
              })}
            </Stack>
          </ThemeProvider>
        </Container>
      </div>
    </>
  );
}

export default App;
